export default {
  "APPLICATION": "APPLICATION",
  "APPS AND PAGES": "APPS AND PAGES",
  "Access Control": "Access Control",
  "Account Settings": "Account Settings",
  "Accounting Summary": "Accounting Summary",
  "Availability List": "Availability List",
  "Availability Report": "Availability Report",
  "Actions": "Actions",
  "Add": "Add",
  "Advance": "Advance",
  "Airline": "Airline",
  "Airlines": "Airlines",
  "Alert": "Alert",
  "Analytics": "Analytics",
  "Apex Chart": "Apex Chart",
  "Apps": "Apps",
  "Authentication": "Authentication",
  "Autocomplete": "Autocomplete",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Basic": "Basic",
  "Button": "Button",
  "CHARTS AND MAPS": "CHARTS AND MAPS",
  "CRM": "CRM",
  "Calendar": "Calendar",
  "Cards": "Cards",
  "Chart": "Chart",
  "Chartjs": "Chartjs",
  "Charts": "Charts",
  "Charts & Maps": "Charts & Maps",
  "Chat": "Chat",
  "Checkbox": "Checkbox",
  "Chip": "Chip",
  "Combobox": "Combobox",
  "Coming Soon": "Coming Soon",
  "Components": "Components",
  "Countries": "Countries",
  "Country": "Country",
  "Customer": "Customer",
  "Customers List": "Customers List",
  "Customer Product Code": "Customer Product Code",
  "Customer Product Codes": "Customer Product Codes",
  "Custom Codes": "Custom Codes",
  "Customers": "Customers",
  "Dashboard": "Dashboard",
  "Daily Deposits": "Daily Deposits",
  "Datatable": "Datatable",
  "Date Picker": "Date Picker",
  "Destination": "Destination",
  "Destinations": "Destinations",
  "Dialog": "Dialog",
  "Disabled Menu": "Disabled Menu",
  "Documentation": "Documentation",
  "Edit": "Edit",
  "Email": "Email",
  "Email Templates": "Email Templates",
  "Error": "Error",
  "Expansion Panel": "Expansion Panel",
  "FAQ": "FAQ",
  "FORMS & TABLES": "FORMS & TABLES",
  "Families": "Families",
  "Family": "Family",
  "File Input": "File Input",
  "Forgot Password V1": "Forgot Password V1",
  "Forgot Password V2": "Forgot Password V2",
  "Form Layout": "Form Layout",
  "Form validation": "Form validation",
  "Forms & Tables": "Forms & Tables",
  "Forms Elements": "Forms Elements",
  "Gamification": "Gamification",
  "Icons": "Icons",
  "Invoice": "Invoice",
  "Invoices": "Invoices",
  "Knowledge Base": "Knowledge Base",
  "Leaflet Maps": "Leaflet Maps",
  "List": "List",
  "List of Shipments": "List of Shipments",
  "Login V1": "Login V1",
  "Login V2": "Login V2",
  "Menu": "Menu",
  "Menu Level 2.1": "Menu Level 2.1",
  "Menu Level 2.2": "Menu Level 2.2",
  "Menu Level 3.1": "Menu Level 3.1",
  "Menu Level 3.2": "Menu Level 3.2",
  "Menu Levels": "Menu Levels",
  "Miscellaneous": "Miscellaneous",
  "Month Picker": "Month Picker",
  "Not Authorized": "Not Authorized",
  "Order": "Order",
  "Orders": "Orders",
  "Others": "Others",
  "Inventory": "Inventory",
  "Zero Out Inventory": "Zero Out Inventory",
  "Pages": "Pages",
  "Pagination": "Pagination",
  "Payments": "Payments",
  "Preview": "Preview",
  "Reports": "Reports",
  "DOF Status": "DOF Status",
  "Pricelists": "Price Lists",
  "Price List": "Price List",
  "Pricing": "Pricing",
  "Product": "Product",
  "Products": "Products",
  "Masters List": "Masters List",
  "Radio": "Radio",
  "Raise Support": "Raise Support",
  "Range Slider": "Range Slider",
  "Rating": "Rating",
  "Register V1": "Register V1",
  "Register V2": "Register V2",
  "Reset Password V1": "Reset Password V1",
  "Reset Password V2": "Reset Password V2",
  "Registrations": "Registrations",
  "Role": "Role",
  "Roles": "Roles",
  "Route": "Route",
  "Routes": "Routes",
  "Sales": "Sales",
  "Select": "Select",
  "Setting": "Setting",
  "Settings": "Settings",
  "Simple Table": "Simple Table",
  "Slider": "Slider",
  "Snackbar": "Snackbar",
  "Statistics": "Statistics",
  "Stepper": "Stepper",
  "Switch": "Switch",
  "Supplier Orders": "Supplier Orders",
  "Supplier DOAs": "Supplier DOAs",
  "Stock Report": "Stock Report",
  "Orders Breakdown": "Orders Breakdown",
  "Tables": "Tables",
  "Tabs": "Tabs",
  "Textarea": "Textarea",
  "Textfield": "Textfield",
  "Time Picker": "Time Picker",
  "Timeline": "Timeline",
  "Tooltip": "Tooltip",
  "Treeview": "Treeview",
  "Typography": "Typography",
  "USER INTERFACE": "USER INTERFACE",
  "Under Maintenance": "Under Maintenance",
  "User": "User",
  "User Interface": "User Interface",
  "User List": "User List",
  "User View": "User View",
  "Users": "Users",
  "Vendor": "Vendor",
  "Vendors": "Vendors",
  "Vendors List": "Vendors List",
  "eCommerce": "eCommerce"
}
