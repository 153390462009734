const apps = [
  //
  //* ——— APPS ——————————————————
  //

  {
    path: '/availability',
    name: 'availability-list',
    component: () => import('@/views/reports/Availability.vue'),
    meta: {
      layout: 'content',
      resource: 'availability-list',
      action: 'manage'
    },
  },
  {
    path: '/pricelists',
    name: 'pricelists',
    component: () => import('@/views/pricelist/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/customer/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/order/List.vue'),
    meta: {
      layout: 'content',
      resource: 'orders',
      action: 'manage'
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/invoice/List.vue'),
    meta: {
      layout: 'content',
      resource: 'invoices',
      action: 'manage'
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/payment/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/products',
    name: 'masters-list',
    component: () => import('@/views/product/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: () => import('@/views/vendor/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/supplier-orders',
    name: 'supplier-orders',
    component: () => import('@/views/supplierOrder/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/supplier-doas',
    name: 'supplier-doas',
    component: () => import('@/views/supplierDoa/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/airlines',
    name: 'airlines',
    component: () => import('@/views/airline/AirlineList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/country/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/destinations',
    name: 'destinations',
    component: () => import('@/views/destination/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/email-templates',
    name: 'email-templates',
    component: () => import('@/views/emailTemplate/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/families',
    name: 'families',
    component: () => import('@/views/family/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/routes',
    name: 'routes',
    component: () => import('@/views/route/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/role/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/user/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/registrations',
    name: 'registrations',
    component: () => import('@/views/registration/RegistrationList.vue'),
    meta: {
      layout: 'content',
    },
  },  
  {
    path: '/customer-product-codes',
    name: 'customer-product-codes',
    component: () => import('@/views/cpc/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dof-status',
    name: 'dof-status',
    component: () => import('@/views/reports/DOFStatus.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/daily-deposits',
    name: 'daily-deposits',
    component: () => import('@/views/reports/DailyDeposits.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('@/views/reports/Sales.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/accounting-summary',
    name: 'accounting-summary',
    component: () => import('@/views/reports/AccountingSummary.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/list-of-shipments',
    name: 'list-of-shipments',
    component: () => import('@/views/reports/ListOfShipments.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orders-breakdown',
    name: 'orders-breakdown',
    component: () => import('@/views/reports/OrdersBreakdown.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/stock-report',
    name: 'stock-report',
    component: () => import('@/views/reports/StockReport.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/zero-inventory',
    name: 'zero-inventory',
    component: () => import('@/views/inventory/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default apps
